<template>
  <v-card-title>
    <div class="d-flex align-center">
      <div class="d-flex">
        <div>
          View Settings -
        </div>
        <div
          v-if="!isEditMode"
          class="ml-1">
          <div v-if="newName">
            {{ newName }}
          </div>
          <div v-else>
            {{ name }}
          </div>
        </div>
        <div
          v-else
          class="edit-name-input-wrapper ml-1">
          <v-text-field
            v-model="modifiedViewName"
            hide-details
            @keyup.enter="save" />
        </div>
      </div>
      <div
        class="ml-1"
        :style="{ marginBottom: '2px'}">
        <v-icon
          v-if="!isEditMode"
          class="--no-transition"
          color="black"
          @click="edit">
          mdi-pencil
        </v-icon>
        <div v-else>
          <v-btn
            height="32px"
            color="blue"
            class="save-edit-name-btn white--text elevation-0"
            @click="save">
            Save
          </v-btn>
        </div>
      </div>
    </div>
    <span
      class="sourcery__icon-wrapper black"
      @click="cancel">
      <v-icon
        color="white"
        size="15">
        mdi-close
      </v-icon>
    </span>
  </v-card-title>
</template>
<script>
export default {
  name: 'ProjectDetailsViewsCardTitle',
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    newName: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    viewName: {
      type: String,
      default: '',
    },
  },
  computed: {
    modifiedViewName: {
      get() {
        return this.viewName;
      },
      set(val) {
        this.$emit('update:viewName', val);
      },
    },
  },
  methods: {
    save() {
      this.$emit('save');
    },
    cancel() {
      this.$emit('cancel');
    },
    edit() {
      this.$emit('edit');
    },
  },
};
</script>
<style scoped lang="scss">
  .edit-name-input-wrapper {
    .v-input{
      padding:0px !important;
      height: 18px !important;
    }
      position: relative;
      bottom: 8px;
  }
  .save-edit-name-btn{
    min-height: unset !important;
    height: 33px !important;
  }
</style>